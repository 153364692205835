<template>
  <v-row justify="center">
    <v-dialog :value="true" max-width="600" @input="$emit('closeDialog')">
      <v-card class="pa-8">
        <!-- init step -->
        <v-row class="justify-end">
          <v-btn icon color="primary" @click="$emit('closeDialog')">
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-1 px-3">
            <h2 class="d-block mb-4" v-text="$t('project.competition.groups.editNameGroup')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.name" outlined dense :label="$t('project.competition.groups.name')"
              :error-messages="getFieldErrors('name')" @blur="$v.form.name.$touch()"
            />
          </v-col>
        </v-row>
        <v-card-actions class="pa-0">
          <v-row>
            <v-col cols="12" class="py-1 px-3 d-sm-flex justify-sm-center">
              <v-btn color="primary" class="w-100 w-sm-auto" @click="save" v-text="$t('common.save')" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'

export default {
  name: 'EditNameGroupDialog',
  mixins: [formUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    cohortId: { type: String, required: true },
    group: { type: Object, required: true },
  },
  data() {
    return { form: { name: this.group.name } }
  },
  validations: { form: { name: { required } } },
  methods: {
    async save() {
      if (!this.isFormValid()) return
      const { organizationId, projectId, cohortId, group: { id: groupId }, form: { name } } = this
      await this.runAsync(() => this.$store.dispatch('competition/updateGroup', { organizationId, projectId, cohortId, groupId, data: { name } }))
      this.$emit('closeDialog')
    },
  },
}
</script>
